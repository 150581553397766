.footer{
    padding: 4rem 6rem 1rem;
    background-color: black ;
    color: #fff;
}

.top{
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top i{
    color: #fff;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover {
    color: #1785b8;
}

.foo{
    font-size: 7px;
    margin-top: 0.5rem;    
}
.bottom{
    padding: 2rem;
    text-align: start;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.bottom div{
    display: flex;
    flex-direction: column;
}

.bottom h4{
    font-size: 1.3rem;
    padding: 1rem 0 0.8rem 0;
}

.bottom a{
    text-decoration: none;
    color: #fff;
    padding-bottom: 0.4rem;
    font-size: 1.1rem;
}
.navbar-logo2{
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 100;
}

@media screen and (max-width: 850px){
    .footer{
        padding: 4rem 2rem 1rem;
    }
    .footer i{
        margin: 1rem 1rem 0 0;
    }
    .bottom div{
        width: 50%;
    }
    .foo{
        font-size: 5px;
        margin-top: 0.5rem;    
    }
}
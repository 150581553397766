.hero{
    width: 100%;
    height: 100vh;
    position: relative;
}

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1{
    font-size: 4rem;
    font-weight: 800;
    background: #558ABB;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
    text-shadow: 1.9px 1.9px #fff;


}
.hero-text h2{
    font-size: 3rem;
    font-weight: 800;
    background: #558ABB;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
    text-shadow: 1.5px 1.5px #fff;

}

.hero-text p{
    font-size: 1.5rem;
    color: #fff;
    padding: 1rem 4rem 2rem 4rem;
    cursor: default;
}

.hero-text .show{
    text-decoration: none;
    background-color: #1785b8;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: #fff;
}
.hero-text .show:hover{
    background-color: #fff;
    color: #222;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

@media screen and (max-width: 555px) {
    .hero-text h1{
        padding: 10px 20px;
    }
    .hero-text p{
        font-size: 1.1rem;
        padding: o o 2rem 0;
    }
    .hero-text .show{
        padding: .6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
        
    }
}

/* Sección About */

.hero-about{
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-about h1{
    padding-top: 4rem !important;
}
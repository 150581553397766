.NavbarItems{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .25);
    width: 100%;
    height: 80px;
    border-radius: 1px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
}

.logo{
    width: 50px;
    margin-top: 0.4rem;
}
.logo-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
}
.navbar-logo{
    color: #222;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 100;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    
}

.Nav-Links{
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.Nav-Links i{
    padding-right: 10px ;
}

.Nav-Links:hover{
    background-color: #1785b8;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.Nav-Links-mobile{
    display: none;
}

button{
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

button:hover{
    background-color: #151516;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.menu-icons{
    display: none;

}

@media screen and (max-width: 850px){
    .NavbarItems{
        z-index: 99;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }
    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .Nav-Links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0 ;
    }

    .Nav-Links:hover{
        background-color: #1785b8;
        transition: none;
    }

    button{
        display: none;

    }

    .Nav-Links-mobile{
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: auto;
        border-radius: 4px;
        width: 80%;
        background: #1785b8;
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: 700;
        letter-spacing: 2px;
    }

    .menu-icons{
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 1.2rem;   
        color: #222 ;     
    }
}
